<template lang="pug">
#root
  md-app.app(:md-scrollbar="false")
    md-app-toolbar.flex.bg-white.md-toolbar
      md-button.md-icon-button.mobile(@click="menuVisible = !menuVisible")
        md-icon menu
      img.header-logo(src="@/assets/images/logo-36Kr.png")
      md-tabs.tabs(md-sync-route)
        md-tab(md-label="游戏开发者峰会", to="/", exact)
        md-tab(md-label="手游出海破新界", to="/campaign/tg2021")
        md-tab(md-label="最新产品与趋势", to="/campaign/gml")
        md-tab(md-label="成就全球强品牌", to="/campaign/competitive_brand")
        md-tab(md-label="跨境电商新篇章", to="/campaign/think_of_retail")
      .spacer
    md-app-drawer(:md-active.sync="menuVisible")
      md-toolbar.md-transparent(md-elevation="0")
        span.md-title
          | 导航
      md-list
        md-list-item(to="/", exact)
          md-icon videogame_asset
          span.md-list-item-text
            | 游戏开发者峰会
        md-list-item(to="/campaign/tg2021")
          md-icon games
          span.md-list-item-text
            | 手游出海破新界
        md-list-item(to="/campaign/gml")
          md-icon trending_up
          span.md-list-item-text
            | 最新产品与趋势
        md-list-item(to="/campaign/competitive_brand")
          md-icon public
          span.md-list-item-text
            | 成就全球强品牌
        md-list-item(to="/campaign/think_of_retail")
          md-icon shopping_cart
          span.md-list-item-text
            | 跨境电商新篇章
    md-app-content.app-container
      .container.cover-container
        img.cover(src="@/assets/images/cover-gml.gif")
        img.cover-mobile(src="@/assets/images/cover-gml.gif")
        p.cover-text
          | Google Marketing Livestream 2021 是针对广告产品发布及营销洞察最大的活动。您将会第一时间发现消费者最新趋势、数字优先业务的成功案例，同时，来自谷歌全球的负责人将宣布创新的广告产品和解决方案，以帮助您的企业应对现在和未来的挑战，更好地制定商业规划。
      .fixed-container
        h2#videos.header-text(ref="videos")
          | 主题演讲
          .header-split
        .videos-container
          .videos
            a.video-card(href="/videos/10", target="_blank")
              .content
                h3.title
                  | 为亚太地区的未来做好准备
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Scott Beaumont.jpg")
                    .details
                      h3.name
                        | Scott Beaumont
                      p
                        | 谷歌亚太区总裁
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/11", target="_blank")
              .content
                h3.title
                  | 亚太地区创业家经验分享
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Cameron Adams.jpg")
                    .details
                      h3.name
                        | Cameron Adams
                      p
                        | Canva 联合创始人兼
                        br
                        | 首席采购官
                  .speaker
                    img.avatar(src="@/assets/images/avatars/William Tanuwijaya.jpg")
                    .details
                      h3.name
                        | William Tanuwijaya
                      p
                        | Tokopedia 联合创始人兼
                        br
                        | 首席执行官
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Si Shen.jpg")
                    .details
                      h3.name
                        | Si Shen
                      p
                        | 木瓜移动 联合创始人兼
                        br
                        | 首席执行官
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/12", target="_blank")
              .content
                h3.title
                  | 以创新技术打通未来营销之路
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Jerry Dischler.jpg")
                    .details
                      h3.name
                        | Jerry Dischler
                      p
                        | 谷歌广告 副总裁
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/12?t=629", target="_blank")
              .content
                h3.title
                  | 下一代的广告衡量
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Vidhya Srinivasan.jpg")
                    .details
                      h3.name
                        | Vidhya Srinivasan
                      p
                        | 谷歌广告购买、分析和衡量 副总裁
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/12?t=1033", target="_blank")
              .content
                h3.title
                  | 利用自动化解锁新机遇
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Darshan Kantak.jpg")
                    .details
                      h3.name
                        | Darshan Kantak
                      p
                        | Google 搜索广告和 Google Ads 产品体验管理 副总裁
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/13", target="_blank")
              .content
                h3.title
                  | 洞悉未来商务趋势，顺应新兴零售业态
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Bill Ready.jpg")
                    .details
                      h3.name
                        | Bill Ready
                      p
                        | Google 商务和支付 总裁
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Harley Finkelstein.jpg")
                    .details
                      h3.name
                        | Harley Finkelstein
                      p
                        | Shopify 总裁
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/13?t=361", target="_blank")
              .content
                h3.title
                  | 消费者历程：从发现商品到完成结账
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Stephanie Shum.jpg")
                    .details
                      h3.name
                        | Stephanie Shum
                      p
                        | 谷歌消费者体验产品管理
                        br
                        | 总监
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/13?t=517", target="_blank")
              .content
                h3.title
                  | 提升品牌影响力，收获忠诚用户
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Jennifer Liu.jpg")
                    .details
                      h3.name
                        | Jenn Liu
                      p
                        | 谷歌产品管理 高级总监
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Greg Revelle.jpg")
                    .details
                      h3.name
                        | Greg Revelle
                      p
                        | Kohl's 高级执行副总裁、
                        br
                        | 首席营销官
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/13?t=831", target="_blank")
              .content
                h3.title
                  | 通过洞察来创新
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Matt Madrigal.jpg")
                    .details
                      h3.name
                        | Matt Madrigal
                      p
                        | Google 商户购物 副总裁
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/13?t=1200", target="_blank")
              .content
                h3.title
                  | 助力商家蓬勃发展
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/avatars/Bill Ready.jpg")
                    .details
                      h3.name
                        | Bill Ready
                      p
                        | Google 商务和支付总裁
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
      .fixed-container
        h2.header-text
          | 最新产品解决方案
          .header-split
      #articles.container
        carousel(:perPage="1", :autoplay="false", :loop="true")
          slide
            .slide-container
              .article-card(
                @click="openUri('https://mp.weixin.qq.com/s/entSkI28DN-vXeExxrmwvA')"
              )
                img.article-image(src="@/assets/images/posts/post_19.jpg")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 线上卖家的出海「再进化」
              .article-card(
                @click="openUri('https://mp.weixin.qq.com/s/5Jq7NrOuwDIs40oKzA5EuA')"
              )
                img.article-image(src="@/assets/images/posts/post_16.jpg")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 借力谷歌平台，提升品牌曝光度和影响力
              .article-card(
                @click="openUri('https://mp.weixin.qq.com/s/5VKthfYgmdzkJzWnA8qolg')"
              )
                img.article-image(src="@/assets/images/posts/post_17.jpg")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 采用可保护隐私的解决方案，确保衡量机制适应未来变化
              .article-card(
                @click="openUri('https://mp.weixin.qq.com/s/IsbURICBP6k2RQxHYqjvgQ')"
              )
                img.article-image(src="@/assets/images/posts/post_18.jpg")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 打造面向未来的创新技术，助您从容应对形势变化
      md-divider
      #downloads.container
        .flex-section.flex-reverse
          .section-content.mobile
            .section-description
              | 谷歌出海计划推出系列线上培训课程：谷歌营销解决方案，重点行业海外市场洞察及独立站正确打法，为出海保驾护航，助力您的海外业务。
          img.section-image(src="@/assets/images/qrcode-gml.jpg")
          .section-content
            h2.section-header.my-4.report-h2
              | 扫描二维码，加入谷歌出海计划
            .section-description.pc
              | 谷歌出海计划推出系列线上培训课程：谷歌营销解决方案，重点行业海外市场洞察及独立站正确打法，为出海保驾护航，助力您的海外业务。
      .footer
        p
          | 如果您也希望尝试谷歌广告解决方案拓展您的海外业务，现在即可致电谷歌专家。
          br.mobile
          | 新用户还可领取最高 400 元优惠券，助力轻松开启谷歌广告之旅。
          br
          | 欢迎拨打：400 - 819 - 1244
          br
          | 工作日：9:00 - 18:00
        md-divider
        img.footer-logo(src="@/assets/images/logo-36Kr-grey.png")
  .hidden(
    :class="{ 'share-mobile': isShare }",
    @click="() => { isShare = false; }"
  )
    img.share-img(src="~@/assets/images/share.png")
  .shares
    .share-icon-container(@click="share")
      md-icon.share-icon share
      .hidden(
        :class="{ 'share-pc': isShare }",
        @click="() => { isShare = false; }"
      )
        img(src="~@/assets/images/share-pc.png", width="150px")
    .share-icon-container(@click="like")
      md-icon.share-icon(:class="{ active: isActive }") favorite
</template>

<script>
export default {
  name: "GML",
  components: {},
  data() {
    return {
      isActive: false,
      isStar: false,
      isShare: false,
      isVideoToEnd: false,
      menuVisible: false,
    };
  },
  methods: {
    like() {
      this.$pushEvent("like", {});
      this.isActive = !this.isActive;
    },
    star() {
      this.isStar = !this.isStar;
    },
    share() {
      this.$pushEvent("share", {});
      this.isShare = !this.isShare;
    },
    openUri(uri) {
      this.$pushEvent("openUri", { url: uri }).then(() => {
        window.location.assign(uri);
        // const ua = navigator.userAgent.toLowerCase();
        // if (
        //   (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        //   ua.indexOf("micromessenger") !== -1
        // ) {
        //   window.location.assign(uri);
        // } else {
        //   window.open(uri, "_blank");
        // }
      });
    },
  },
  mounted() {
    this.$pushEvent("visit", { route: "home", source: window.location.search });

    // This is a hack to Chromium bug
    if (window.location.hash.length > 0) {
      const hash = window.location.hash.substring(1);
      document.getElementById(hash).scrollIntoView();
      // hack to Safari bug
      const ua = navigator.userAgent.toLowerCase();
      if (
        (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        ua.indexOf("micromessenger") !== -1
      ) {
        window.setTimeout(() => {
          document.getElementById(hash).scrollIntoView();
        }, 100);
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h2 {
  text-align: center;
}

.header-split {
  width: 100%;
  height: 3px;
  margin-top: 8px;
  background: #448aff;
}

h3 {
  font-size: 15px;
}

a {
  cursor: pointer;
}

.report-h2 {
  font-size: 19px;
}

.tabs {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.md-toolbar {
  min-height: 48px;
}

.md-toolbar .md-tabs {
  margin: 0 !important;
}

.pc {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.mobile {
  @media screen and (min-width: 1280px) {
    display: none;
  }

  display: block;
}

.arrow-container {
  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
  }

  display: none;
}

.arrow {
  @media screen and (min-width: 1280px) {
    top: 10px;
    z-index: 9;
    display: block;
    cursor: pointer;
  }
}

.arrow-back {
  margin-right: 10px;
}

.arrow-forward {
  margin-left: 10px;
}

.arrow-icon {
  background-color: #cccccc;
  width: 10px;
  height: 10px;
  border-radius: 5px;

  &.active {
    background-color: #000000;
  }
}

.share-img {
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
}

.hidden {
  display: none;
}

.show-player {
  display: flex;
}

.share-pc {
  @media screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    left: -150px;
    top: -90px;
  }
}

.share-mobile {
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
  }
}

.shares {
  position: fixed;
  background-color: #fff;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 1280px) {
    top: 30%;
  }
}

.share-icon-container {
  margin: 10px;
  cursor: pointer;
}

.share-icon {
  color: #000 !important;
}

.close-icon {
  margin: 0 0 0 auto;
  cursor: pointer;
}

.active {
  color: #ff0000 !important;
}

.star {
  color: #ffcc00 !important;
}

.my-4 {
  margin-bottom: 20px;
}

.my-8 {
  margin-bottom: 40px;
}

.app {
  max-width: 100vw;
  max-height: 100vh;
  position: absolute;
}

.app-container {
  max-width: 100vw;
  max-height: 100vh;
}

.flex {
  display: flex;
}

.spacer {
  flex: 1;
}

.header-logo {
  height: 24px;
}

.padding-btn {
  padding: 5px 10px;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.fixed-container {
  max-width: 1200px;
  margin: 0 auto;
}

.cover-container {
  padding: 50px 0 50px 0;

  @media screen and (min-width: 1280px) {
    padding: 50px 200px;
  }

  background: #f3ece5;
}

.cover {
  display: none;
  width: 100vw;
  margin: auto;

  @media screen and (min-width: 1280px) {
    display: block;
  }
}

.cover-text {
  padding: 0 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 0 50px;
    font-size: 20px;
  }
}

.header-text {
  padding: 16px 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 16px 50px;
    font-size: 20px;
  }

  margin: 0;
}

.cover-mobile {
  display: block;
  margin: 60px auto;

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

.md-content {
  padding: 0;
}

.article-content-title {
  color: rgb(117, 117, 117);
}

.bg-grey {
  background-color: #f2f2f2;
}

.bg-white {
  background-color: #fff;
}

.md-card-media img {
  border-radius: 32px 32px 0 0;
}

.span-btn:hover {
  text-decoration: none;
}

.span-text {
  display: inline-block;
  line-height: 24px;
  vertical-align: middle;
}

.slide-container {
  @media screen and (min-width: 1280px) {
    width: 800px;
  }

  width: 90%;
  margin: 0 auto;
}

.article-card {
  display: flex;
  cursor: pointer;
  margin: 20px 0;
}

.article-image {
  @media screen and (min-width: 1024px) {
    width: 200px;
    height: calc(200px / 4 * 3);
    border-radius: 32px;
    margin: 0 30px 0 0;
  }

  width: 140px;
  height: calc(140px / 4 * 3);
  border-radius: 16px;
  margin: 0 15px 0 0;
}

.article-content {
  flex: 4;
}

.flex-section {
  display: flex;
  padding: 40px 0;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    padding: 60px 0;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }

  flex-direction: column;
}

.section-description {
  margin: 0 20px 10px 20px;
}

.section-image {
  max-width: 90%;

  @media screen and (min-width: 1280px) {
    max-width: 50%;
  }

  width: auto;
  height: auto;
  justify-self: flex-start;
  align-self: flex-start;
  margin: 0 auto;
}

.section-content {
  @media screen and (min-width: 1280px) {
    width: 480px;
    padding: 0 40px;
  }

  padding: 0 10px;
  margin: auto 0;
}

.lg-btn {
  width: 220px;
  margin: 20px auto;
  display: block;
}

.flex-reverse {
  @media screen and (min-width: 1280px) {
    flex-direction: row-reverse;
  }

  flex-direction: column;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px;

  .footer-logo {
    margin: 20px 0 0 0;
    // margin: 20px auto 0 auto; 如果需要左右居中，使用此行
    height: 20px;
    display: block;
  }
}

.video-card {
  display: flex;
  margin: 16px 5px 16px 20px;
  padding: 10px 5px;

  @media screen and (min-width: 1280px) {
    margin: 16px 50px 16px 50px;
    padding: 10px 5px 10px 80px;
  }

  h2 {
    text-align: left;
    color: #000;
  }

  h3 {
    text-align: left;
    color: #000;
  }

  .title  {
    font-weight: normal;
    font-size: 20px;
    margin: 5px 0 30px 0;
  }

  .name {
    margin: 0;
  }

  .content {
    flex: 1;
    .content-details {
      @media screen and (min-width: 1280px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .speaker {
        display: flex;
        flex: 1;
        padding: 20px 0;
        @media screen and (min-width: 1280px) {
          padding: 0;
        }
        .avatar {
          width: 64px;
          height: 64px;
          border-radius: 32px;
        }
        .details {
          flex: 1;
          margin: 0 20px;
          color: #858585;
        }
      }
    }
  }

  .btn-play {
    width: 64px;
  }
}

.video-card:nth-child(even) {
  background: #ffffff;
}

.video-card:nth-child(odd) {
  background: #f8f9fb;
}
</style>
